import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import HomeComponent from "./components/HomeComponent";
import Header from "./wrappers/Header";
import Footer from "./wrappers/Footer";
import ProductsComponent from "./components/ProductsComponent";
import ProductsSingleComponent from "./components/ProductsSingleComponent";
import UserAccount from "./components/UserAccount";
import StoresComponent from "./components/StoresComponent";
import SellingContract from "./components/SellingContract";
import PrivacySecurity from "./components/PrivacySecurity";
import FAQComponent from "./components/FAQComponent";
import TermsOfUse from "./components/TermsofUse";
import AboutUsComponent from "./components/AboutUsComponent";
import LoginComponent from "./components/LoginComponent";
import MisionComponent from "./components/MisionComponent";
import ContactComponent from "./components/ContactComponent";
import NewestMainComponent from "./components/NewestMainComponent";
import BestSellerMainComponent from "./components/BestSellerMainComponent";
import SmellsComponent from "./components/SmellsComponent";
import MobileMenu from "./wrappers/MobileMenu";
import ShoppingCart from "./wrappers/ShoppingCart";
import CheckoutComponent from "./components/CheckoutComponent";
import OrderTrackingComponent from "./components/OrderTrackingComponent";
import OrderTrackingDetailsComponent from "./components/OrderTrackingDetailsComponent";
import RememberPassword from "./components/RememberPassword";
import NotFoundComponent from "./components/NotFoundComponent";
import BlogComponenent from "./components/BlogComponenent";
import BlogSingleComponent from "./components/BlogSingleComponent";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ScrollToTop from "./utils/ScrollToTop";
import StatusModal from "./wrappers/StatusModal";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import config from "./config";
import { setToken } from "./redux/actions/tokenActions";
import { ToastContainer } from "react-toastify";
import urlTr from "./data/url-turkish.json";
import urlEn from "./data/url-english.json";
import Whatsapp from "./wrappers/Whatsapp";
import useStorageListener from "./utils/useStorageListener";

const App = () => {
  useStorageListener();
  const userDetails = useSelector((state) => state.account.userDetails);
  const language = useSelector((state) => state.language.language);
  const { REACT_APP_BASE_URL } = process.env;
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails && userDetails.email) {
      const refreshToken = async () => {
        try {
          const response = await axios.post(
            REACT_APP_BASE_URL + "users/refresh-token",
            { email: userDetails.email },
            {
              headers: {
                "Content-Type": "application/json",
                xsfr: config.secretKey,
              },
            }
          );
          dispatch(setToken(response.data.token));
        } catch (error) {
          console.error("Refresh token error:", error);
        }
      };

      const refreshInterval = setInterval(refreshToken, 30 * 60 * 1000); // 10 dakika

      return () => clearInterval(refreshInterval);
    }
  }, [dispatch, userDetails]);

  return (
    <BrowserRouter>
      <div className="d-flex flex-column justify-content-between">
        <Header />
        <MobileMenu />
        <Whatsapp />
        <ScrollToTop />
        <Routes>
          <Route
            path="/:language/check-out"
            element={
              <ProtectedRoute>
                <CheckoutComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:language/user/*"
            element={
              <ProtectedRoute>
                <UserAccount />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/:language/urunler"
            element={<ProductsComponent />}
          />
          <Route path="/" element={<Navigate to={`/${language}`} />} />

          <Route exact path="/:language" element={<HomeComponent />} />
          <Route
            exact
            path="/:language/kategoriler"
            element={<SmellsComponent />}
          />
          <Route
            exact
            path="/:language/siparis-takip"
            element={<OrderTrackingComponent />}
          />
          <Route
            exact
            path="/:language/sifremi-unuttum"
            element={<RememberPassword />}
          />
          <Route
            exact
            path="/:language/siparis-takip-detay"
            element={<OrderTrackingDetailsComponent />}
          />
          <Route
            exact
            path="/:language/urunler/:category_name"
            element={<ProductsComponent />}
          />
          <Route
            exact
            path="/:language/kategoriler/:kokuprofili"
            element={<SmellsComponent />}
          />
          <Route
            exact
            path="/:language/magazalarimiz"
            element={<StoresComponent />}
          />
          <Route
            exact
            path="/:language/urun-detay/:page_url"
            element={<ProductsSingleComponent />}
          />

          <Route
            exact
            path="/:language/mesafeli-satis-sozlesmesi"
            element={<SellingContract />}
          />
          <Route
            exact
            path="/:language/gizlilik-guvenlik"
            element={<PrivacySecurity />}
          />
          <Route
            exact
            path="/:language/kullanim-sartlari"
            element={<TermsOfUse />}
          />
          <Route
            exact
            path="/:language/hakkimizda"
            element={<AboutUsComponent />}
          />
          <Route exact path="/:language/login" element={<LoginComponent />} />
          <Route
            exact
            path="/:language/sikca-sorulan-sorular"
            element={<FAQComponent />}
          />
          <Route
            exact
            path="/:language/magazalarimiz"
            element={<StoresComponent />}
          />

          <Route
            exact
            path="/:language/en-yeniler"
            element={<NewestMainComponent />}
          />
          <Route
            exact
            path="/:language/cok-satanlar"
            element={<BestSellerMainComponent />}
          />

          <Route
            exact
            path="/:language/misyonumuz"
            element={<MisionComponent />}
          />
          <Route
            exact
            path="/:language/iletisim"
            element={<ContactComponent />}
          />
          <Route exact path="/:language/shop" element={<ShoppingCart />} />
          <Route exact path="/:language/blog" element={<BlogComponenent />} />
          <Route
            exact
            path="/:language/blog/:title"
            element={<BlogSingleComponent />}
          />
          <Route
            exact
            path="/:language/perfume-order"
            element={<StatusModal />}
          />
          <Route path=":language/*" element={<NotFoundComponent />} />
          {/* <Route path=":language/*" element={<Navigate to="/not-found" />} /> */}

          {urlTr.length > 0 &&
            urlTr.map((item) => {
              return (
                <Route
                  path={item.url_eski}
                  element={<Navigate to={item.url_yeni} replace />}
                />
              );
            })}
          {urlEn.length > 0 &&
            urlEn.map((item) => {
              return (
                <Route
                  path={item.url_eski}
                  element={<Navigate to={item.url_yeni} replace />}
                />
              );
            })}
        </Routes>
        <Footer />
        <ToastContainer />
      </div>
    </BrowserRouter>
  );
};

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps)(App);
