import React, { useState, useEffect } from "react";
import "../assets/css/checkout.css";
import Accordion from "react-bootstrap/Accordion";
import noImage from "../assets/img/no_pic.webp";
import Adress from "../wrappers/Adress";
import { useSelector } from "react-redux";
import NewCustomer from "../wrappers/NewCustomer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import sepetIcon from "../assets/img/icons/siparis-sepet.svg";
import {
  getCartTotal,
  increaseCounter,
  decreaseCounter,
} from "../redux/actions/cartActions";
import config from "../config";
import useStorageListener from "../utils/useStorageListener";

const { REACT_APP_BASE_URL } = process.env;

const CheckoutComponent = ({ cartItems }) => {
  useStorageListener();
  const [showDiscountInput, setShowDiscountInput] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isIndividualChecked, setIsIndividualChecked] = useState(true);
  const [isCorporateChecked, setIsCorporateChecked] = useState(false);
  const [showKurumsalText, setShowKurumsalText] = useState(false);
  const [isKurumsalAdresChecked, setKurumsalAdresChecked] = useState(true);
  const [showKurumsalAdres, setShowKurumsalAdres] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [isDiscountValid, setIsDiscountValid] = useState(false);
  const [ccholdername, setcc_holder_name] = useState("");
  const [ccno, setcc_no] = useState("");
  const [expirymonth, setexpiry_month] = useState("");
  const [expiryyear, setexpiry_year] = useState("");
  const [cvv, setcvv] = useState("");
  const [couponid, setcouponid] = useState("");
  const userDetails = useSelector((state) => state.account.userDetails);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [selectedShippingAdrress, setSelectedShippingAdrress] = useState("");
  const [selectedInvoiceAdrress, setSelectedInvoiceAdrress] = useState("");
  const [invoice_type, setInvoiceType] = useState("Bireysel");
  const [invoice_corporate_name, setInvoiceCorporateName] = useState("");
  const [tax_number, setTaxNumber] = useState("");
  const [tax_office, setTaxOffice] = useState("");
  const [efaturaChecked, setEfaturaChecked] = useState(false);
  const [activeAccordionKey, setActiveAccordionKey] = useState("Kredi Kartı");
  const token = useSelector((state) => state.token);
  const [cargoArr, setCargoArr] = useState([]);
  const [cartShopping, setCartShopping] = useState([...cartItems]);
  const [cargoPrice, setCargoPrice] = useState(0);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [cartId, setCartId] = useState(
    cartItems.length > 0 ? cartItems[0].cart_id : null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCargoPrice = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}cargo-price/read`,
          {
            headers: {
              xsfr: config.secretKey,
              Authorization: `Bearer ${token.tokenAuth}`,
            },
          }
        );

        setCargoArr(response.data.response);
      } catch (error) {
        console.error("Error fetching cargo price:", error);
      }
    };

    fetchCargoPrice();
  }, []);
  useEffect(() => {
    dispatch(getCartTotal());
  }, [dispatch]);

  const updateCart = async (updatedCart) => {
    try {
      const response = await axios.post(
        REACT_APP_BASE_URL + "cart/update",
        {
          cart_id: cartId,
          comb_atts: updatedCart.map((item) => ({
            comb_att_id: item.comb_att_id,
            quantity: item.count.toString(),
          })),
        },
        {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        }
      );
    } catch (error) {
      throw new Error("Error updating cart:", error);
    }
  };

  const decreaseCounterHandler = async (index) => {
    const updatedCart = [...cartShopping];
    if (couponid) {
      handleApplyDiscount();
    }
    if (updatedCart[index].count > 1) {
      updatedCart[index] = {
        ...updatedCart[index],
        count: updatedCart[index].count - 1,
      };
      localStorage.setItem("taskCompleted", Date.now());

      dispatch(decreaseCounter(index));

      try {
        await updateCart(updatedCart);
        setCartShopping(updatedCart);
      } catch (error) {}
    }
  };

  const formatCreditCardNumber = (input) => {
    const cleanInput = input.replace(/\D/g, "");
    let formattedInput = cleanInput.replace(/(\d{4})(?=\d)/g, "$1 ");
    return formattedInput;
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatCreditCardNumber(inputValue);
    setcc_no(formattedValue);
  };

  const isNumericCC = (value) => {
    return /^\d+$/.test(value);
  };

  const increaseCounterHandler = async (index) => {
    const updatedCart = [...cartShopping];
    if (couponid) {
      handleApplyDiscount();
    }
    updatedCart[index] = {
      ...updatedCart[index],
      count: updatedCart[index].count + 1,
    };
    localStorage.setItem("taskCompleted", Date.now());

    dispatch(increaseCounter(index));

    try {
      await updateCart(updatedCart);
      setCartShopping(updatedCart);
    } catch (error) {
      // console.error(error.message);
    }
  };

  const calculateTotalAmount = () => {
    let total = 0;
    cartShopping.forEach((item) => {
      const price = parseFloat(item.product_price);
      const quantity = item.count;
      total += price * quantity;
    });

    return total;
  };

  useEffect(() => {
    let total = calculateTotalAmount() - discount;
    const result = cargoArr.filter(
      (item) => item.min_price < total && item.max_price > total
    );

    if (result.length > 0) {
      setFinalTotal(total + result[0].cargo_price);

      setCargoPrice(result[0].cargo_price);
    }
  }, [cargoArr, cartShopping, discount]);

  const isNumeric = (str) => {
    if (typeof str !== "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  };

  const isNameValid = (name) => {
    return name.trim().length > 0;
  };

  const isExpiryValid = (month, year) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const isNumeric = (str) => {
      if (typeof str != "string") return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    };

    if (!isNumeric(month) || !isNumeric(year)) return false;

    if (year.length === 2) {
      year = "20" + year;
    }

    if (parseInt(year) < currentYear) return false;
    if (parseInt(year) === currentYear && parseInt(month) < currentMonth)
      return false;
    if (parseInt(month) < 1 || parseInt(month) > 12) return false;

    return true;
  };

  const isCVVValid = (cvv) => {
    return /^[0-9]{3,4}$/.test(cvv);
  };
  const handleTaxNumberChange = (e) => {
    const input = e.target.value;
    if (/^\d{0,10}$/.test(input)) {
      setTaxNumber(input);
    }
  };

  const validateTaxNumber = () => {
    if (tax_number.length < 10) {
      toast.error(data.vergi_no_on_hane);
      return false;
    }
    return true;
  };
  const handleCompanyNameChange = (e) => {
    setInvoiceCorporateName(e.target.value);
  };

  const handleTaxOfficeChange = (e) => {
    setTaxOffice(e.target.value);
  };

  const handleApplyDiscount = () => {
    axios
      .post(
        REACT_APP_BASE_URL + "coupon/read",
        {
          user_id: userDetails.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        }
      )
      .then((responses) => {
        const coupon = responses.data.response.find(
          (c) => c.coupon_code === discountCode
        );

        if (coupon) {
          axios
            .post(
              REACT_APP_BASE_URL + "coupon/allow-coupon",
              {
                user_id: userDetails.user_id,
                coupon_id: coupon.coupon_id,
                coupon_device: "web_device",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  xsfr: config.secretKey,
                  Authorization: `Bearer ${token.tokenAuth}`,
                },
              }
            )
            .then((res) => {
              if (
                res.data.response == "authorized" &&
                res.data.responseCode == 202
              ) {
                if (coupon.coupon_type === "%") {
                  setDiscount(
                    (calculateTotalAmount() * coupon.coupon_value) / 100
                  );
                } else if (coupon.coupon_type === "₺") {
                  setDiscount(coupon.coupon_value);
                }
                setIsDiscountValid(true);
                setcouponid(coupon.coupon_id);
              } else {
                toast.error(data.gecersiz_indirim_kodu);
              }
            })
            .catch((err) => {
              toast.error(data.gecersiz_indirim_kodu);
            });
        } else {
          // console.log(data)
          toast.error(data.gecersiz_indirim_kodu);
        }
      })
      .catch((error) => {
        console.error("Error fetching coupons:", error);
        toast.error(data.gecersiz_indirim_kodu);
      });
  };

  const handleIndividualChange = () => {
    setIsIndividualChecked(true);
    setIsCorporateChecked(false);
  };

  const handleCorporateChange = () => {
    setIsCorporateChecked(true);
    setIsIndividualChecked(false);
  };

  useEffect(() => {
    if (isIndividualChecked) {
      setInvoiceType("Bireysel");
      setShowKurumsalText(false);
    } else if (isCorporateChecked) {
      setInvoiceType("Kurumsal");
      setShowKurumsalText(true);
    }
  }, [isIndividualChecked, isCorporateChecked]);

  const handleKurumsalAdresChange = (e) => {
    setKurumsalAdresChecked(e.target.checked);
    setShowKurumsalAdres(!e.target.checked);
    if (e.target.checked == false) {
      setSelectedInvoiceAdrress("");
    } else {
      setSelectedInvoiceAdrress(selectedShippingAdrress);
    }
  };

  const indirimTutari = discount;

  useEffect(() => {
    if (isKurumsalAdresChecked) {
      setSelectedInvoiceAdrress(selectedShippingAdrress);
    }
  }, [selectedShippingAdrress]);

  const ordersSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) return; 

    setIsSubmitting(true);
    if (isCorporateChecked) {
      if (!validateTaxNumber()) {
        setIsSubmitting(false); // İşlem tamamlandığında butonu tekrar etkinleştir
        return;
      }

      if (!invoice_corporate_name) {
        toast.error(data.sirket_adi_bos_birakilamaz);
        setIsSubmitting(false);
        return;
      }
      if (!tax_office) {
        toast.error(data.vergi_dairesi_bos_birakilamaz);
        setIsSubmitting(false);
        return;
      }
    }

    if (!isPrivacyChecked) {
      toast.error(data.gizlilik_satis_onayla);
      setIsSubmitting(false);
      return;
    } else if (!selectedShippingAdrress || !selectedInvoiceAdrress) {
      toast.error(data.lutfen_adres_secin);
      setIsSubmitting(false);
      return;
    }

    const adressShipping =
      selectedShippingAdrress.address_area1 +
      " " +
      selectedShippingAdrress.address_area2 +
      " " +
      selectedShippingAdrress.postcode +
      " " +
      selectedShippingAdrress.district +
      "/" +
      selectedShippingAdrress.city +
      " " +
      selectedShippingAdrress.phone_no;
    const adressBilling =
      selectedInvoiceAdrress.address_area1 +
      " " +
      selectedInvoiceAdrress.address_area2 +
      " " +
      selectedInvoiceAdrress.postcode +
      " " +
      selectedInvoiceAdrress.district +
      "/" +
      selectedInvoiceAdrress.city +
      " " +
      selectedInvoiceAdrress.phone_no;

    if (activeAccordionKey == "Kredi Kartı") {
      if (!isNameValid(ccholdername)) {
        toast.error(data.gecerli_kart_sahibi_ismi_girin);
        setIsSubmitting(false);

        return;
      }

      if (!isExpiryValid(expirymonth, expiryyear)) {
        toast.error(data.gecersiz_son_kullanma_tarihi);
        setIsSubmitting(false);

        return;
      }

      if (!isCVVValid(cvv)) {
        toast.error(data.gecersiz_cvv);
        setIsSubmitting(false);

        return;
      }
      const obj = {
        user_id: userDetails.user_id,
        cargo_price: cargoPrice,
        cart_id: cartId,
        order_type: "Kredi Kartı",
        order_device: "web",
        coupon_id: couponid,
        cc_holder_name: ccholdername,
        cc_no: ccno && ccno.replaceAll(" ", ""),
        expiry_month: expirymonth,
        expiry_year: expiryyear,
        cvv,
        shipping_address: adressShipping,
        billing_address: adressBilling,
        invoice_type,
        invoice_corporate_name,
        invoice_tax_office: tax_office,
        invoice_tax_number: tax_number,
        invoice_e_fatura: efaturaChecked,
        shipping_address_area1: selectedShippingAdrress.address_area1,
        shipping_address_area2: selectedShippingAdrress.address_area2,
        shipping_city: selectedShippingAdrress.city,
        shipping_district: selectedShippingAdrress.district,
        phone_number:
          selectedShippingAdrress.phone_no || userDetails.phone_number,
        shipping_postcode: selectedShippingAdrress.postcode,
        billing_address_area1: selectedInvoiceAdrress.address_area1,
        billing_address_area2: selectedInvoiceAdrress.address_area2,
        billing_city: selectedInvoiceAdrress.city,
        billing_district: selectedInvoiceAdrress.district,
        billing_postcode: selectedInvoiceAdrress.postcode,
      };

      axios
        .post(REACT_APP_BASE_URL + "perfume-order/add-order", obj, {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        })
        .then((res) => {
          localStorage.setItem("invoice_id", res.data.invoice_id);
          localStorage.setItem("cart_id", res.data.cart_id);

          toast.success(data.odeme_sayfasina_yonlendiriliyorsunuz);

          window.location.replace(
            `${REACT_APP_BASE_URL}sipay?id=${res.data.invoice_id}`
          );
          setIsSubmitting(false); // İşlem tamamlandığında butonu tekrar etkinleştir
        })
        .catch((err) => {
          console.error("Error response:", err.response);

          toast.error(err.response.data.message);
          setIsSubmitting(false); // Hata durumunda butonu tekrar etkinleştir
        });
    } else {
      const obj = {
        user_id: userDetails.user_id,
        cart_id: cartId,
        cargo_price: cargoPrice,
        order_type: "Havale",
        order_device: "web",
        coupon_id: couponid,
        shipping_address: adressShipping,
        billing_address: adressBilling,
        invoice_type,
        invoice_corporate_name,
        invoice_tax_office: tax_office,
        invoice_tax_number: tax_number,
        invoice_e_fatura: efaturaChecked,
        shipping_address_area1: selectedShippingAdrress.address_area1,
        shipping_address_area2: selectedShippingAdrress.address_area2,
        shipping_city: selectedShippingAdrress.city,
        shipping_district: selectedShippingAdrress.district,
        shipping_postcode: selectedShippingAdrress.postcode,
        phone_number:
          selectedShippingAdrress.phone_no || userDetails.phone_number,
        billing_address_area1: selectedInvoiceAdrress.address_area1,
        billing_address_area2: selectedInvoiceAdrress.address_area2,
        billing_city: selectedInvoiceAdrress.city,
        billing_district: selectedInvoiceAdrress.district,
        billing_postcode: selectedInvoiceAdrress.postcode,
      };

      axios
        .post(REACT_APP_BASE_URL + "perfume-order/add-order", obj, {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        })
        .then((res) => {
          localStorage.setItem("invoice_id", res.data.invoice_id);
          toast.success(data.siparis_basarili_olusturuldu);
          window.location.replace(`
            /perfume-order`);
        })
        .catch((err) => {
          console.error("Error response:", err.response);
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <>
      <div className="col-lg-10 col-md-12 col-12 main-space container-fluid check-out-main">
        <div className="col-12 d-flex align-items-start">
          <div className="col-12">
            {!isLoggedIn && (
              <h5 className="check-out-login-title">
                Hesabınız var mı ?{" "}
                <a href={`/${language}/login`}>Giriş Yapın</a>
              </h5>
            )}
            <div className="card-type-two p-lg-5 p-4">
              {isLoggedIn ? (
                <Adress
                  address_type={"shipping"}
                  setSelectedInvoiceAdrress={setSelectedInvoiceAdrress}
                  setSelectedShippingAdrress={setSelectedShippingAdrress}
                />
              ) : (
                <NewCustomer />
              )}
            </div>
            <div className="card-type-two p-lg-5 p-4 mt-3">
              <div className="d-xl-flex">
                <div className="col-xl-6 col-md-12 col-12">
                  <h5>{data.odeme_yontemi} </h5>
                  <div className="d-lg-flex d-md-flex justify-content-between mb-3">
                    {" "}
                    <div className="col-lg-5 col-md-5 col-12 checkbox-inner">
                      <input
                        type="checkbox"
                        id="kredi-karti"
                        name="kredi-karti"
                        checked={activeAccordionKey === "Kredi Kartı"}
                        onChange={() => setActiveAccordionKey("Kredi Kartı")}
                      />
                      <label
                        className="ps-lg-2 ps-md-2 ps-0"
                        htmlFor="kredi-karti"
                      >
                        {data.kredi_karti_odeme}
                      </label>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12 d-flex justify-content-start checkbox-inner mt-lg-0 mt-md-0 mt-3">
                      <input
                        type="checkbox"
                        id="havale"
                        name="havale"
                        checked={activeAccordionKey === "Havale"}
                        onChange={() => setActiveAccordionKey("Havale")}
                      />
                      <label className="ps-lg-2 ps-md-2 ps-0" htmlFor="havale">
                        {data.havale_eft}
                      </label>
                    </div>
                  </div>

                  <Accordion
                    activeKey={activeAccordionKey}
                    onSelect={(key) => setActiveAccordionKey(key)}
                  >
                    <Accordion.Item
                      eventKey="Kredi Kartı"
                      style={{
                        display:
                          activeAccordionKey === "Kredi Kartı"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Accordion.Header>
                        {data.kredi_karti_odeme}
                        {activeAccordionKey === "Kredi Kartı" && (
                          <p className="p-0 m-0"> &nbsp;- {data.secili} </p>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <label>{data.kart_no} </label>
                          <input
                            className="w-100 mt-2"
                            type="text"
                            value={ccno}
                            onChange={handleInputChange}
                            placeholder={data.kart_no}
                            maxLength={19}
                          />
                          {ccno.length < 19 && !isNumericCC(ccno) && (
                            <p className="text-gray">{data.gecerli_bir_kart}</p>
                          )}

                          <label>{data.kart_ismi} </label>
                          <input
                            className="w-100 mt-2"
                            type="text"
                            placeholder={data.kart_ismi}
                            value={ccholdername}
                            onChange={(e) => setcc_holder_name(e.target.value)}
                          />
                          {!isNameValid(ccholdername) && (
                            <p className="text-gray">
                              {data.gecerli_bir_isim}{" "}
                            </p>
                          )}
                        </div>
                        <div className="d-flex gap-3 ">
                          <div>
                            <label>{data.ay} </label>
                            <input
                              className="w-100"
                              type="text"
                              placeholder={data.ay}
                              value={expirymonth}
                              onChange={(e) => setexpiry_month(e.target.value)}
                              maxLength={2}
                            />
                            {!isNumeric(expirymonth) && (
                              <p className="text-gray">
                                {data.gecerli_bir_ay}{" "}
                              </p>
                            )}
                          </div>
                          <div>
                            <label>{data.yil} </label>
                            <input
                              className="w-100"
                              type="text"
                              placeholder="Yıl"
                              value={expiryyear}
                              onChange={(e) => setexpiry_year(e.target.value)}
                              maxLength={4}
                            />
                            {!isNumeric(expiryyear) && (
                              <p className="text-gray">
                                {data.gecerli_bir_yil}
                              </p>
                            )}
                          </div>
                          <div>
                            <label>{data.CVV} </label>
                            <input
                              className="w-100"
                              type="text"
                              placeholder={data.CVV}
                              value={cvv}
                              onChange={(e) => setcvv(e.target.value)}
                              maxLength={3}
                            />
                            {!isCVVValid(cvv) && (
                              <p className="text-gray">
                                {data.gecerli_bir_CVV}
                              </p>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey="Havale"
                      style={{
                        display:
                          activeAccordionKey === "Havale" ? "block" : "none",
                      }}
                    >
                      <Accordion.Header>
                        {data.havale_eft}
                        {activeAccordionKey === "Havale" && (
                          <p className="p-0 m-0"> &nbsp;- {data.secili} </p>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="havale-border">
                          <h5>KUDRA KİMYA SANAYİ VE TİCARET A.Ş</h5>
                          <p>IBAN : TR790006701000000096236450</p>
                          <p>Yapı Kredi Bankası</p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <div className="mt-2">
                      <div className="d-flex align-items-center ">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          checked={isKurumsalAdresChecked}
                          onChange={(e) => handleKurumsalAdresChange(e)}
                        />
                        <label
                          className="ps-lg-2 ps-md-2 ps-0"
                          htmlFor="vehicle1"
                        >
                          {data.fatura_teslimat_aciklama}
                        </label>
                      </div>
                    </div>
                    {showKurumsalAdres && (
                      <div className="mt-2">
                        <Adress
                          address_type={"invoice"}
                          setSelectedInvoiceAdrress={setSelectedInvoiceAdrress}
                          setSelectedShippingAdrress={
                            setSelectedShippingAdrress
                          }
                        />
                      </div>
                    )}
                    <div className="mt-3 d-lg-flex d-md-flex justify-content-between">
                      <div className="col-lg-5 col-md-5 col-12 d-flex checkbox-inner ">
                        <div className="d-flex align-items-center justify-content-center">
                          {" "}
                          <input
                            type="checkbox"
                            id="bireysel"
                            name="bireysel"
                            checked={isIndividualChecked}
                            onChange={(e) => handleIndividualChange(e)}
                          />
                          <label
                            className="ps-lg-2 ps-md-2 ps-0"
                            htmlFor="bireysel"
                          >
                            {data.bireysel}
                          </label>
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-5 col-md-5 col-12 d-flex checkbox-inner">
                        <div className="d-flex align-items-center justify-content-center">
                          {" "}
                          <input
                            type="checkbox"
                            id="kurumsal"
                            name="kurumsal"
                            checked={isCorporateChecked}
                            onChange={(e) => handleCorporateChange(e)}
                          />
                          <label
                            className="ps-lg-2 ps-md-2 ps-0"
                            htmlFors="kurumsal"
                          >
                            {data.kurumsal}
                          </label>
                        </div>

                        <br />
                      </div>
                    </div>
                    {showKurumsalText && (
                      <div className="mt-2">
                        <div className="">
                          <div className="col-12">
                            <input
                              className="w-100"
                              type="text"
                              placeholder={data.sirket_adi}
                              value={invoice_corporate_name}
                              required
                              onChange={handleCompanyNameChange}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <input
                              className="w-100"
                              type="text"
                              placeholder={data.vergi_dairesi}
                              value={tax_office}
                              required
                              onChange={handleTaxOfficeChange}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <input
                              className="w-100"
                              type="text"
                              placeholder={data.vergi_numarasi}
                              value={tax_number}
                              required
                              onChange={handleTaxNumberChange}
                            />
                          </div>
                          <div className="mt-2">
                            <div className="d-flex align-items-center ">
                              <input
                                type="checkbox"
                                id="fatura"
                                name="fatura"
                                checked={efaturaChecked}
                                onChange={(e) =>
                                  setEfaturaChecked(e.target.checked)
                                }
                              />
                              <label
                                className="ps-lg-2 ps-md-2 ps-0"
                                htmlFor="vehicle2"
                              >
                                {data.mukellef}
                              </label>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="mt-3">
                      <div className="d-flex align-items-center ">
                        <input
                          type="checkbox"
                          id="security"
                          name="security"
                          defaultValue=""
                          checked={isPrivacyChecked}
                          onChange={() =>
                            setIsPrivacyChecked(!isPrivacyChecked)
                          }
                        />
                        <label
                          className="ps-lg-2 ps-md-2 ps-0"
                          htmlFor="vehicle2"
                        >
                          <a
                            target="_blank"
                            href={`/${language}/gizlilik-guvenlik`}
                          >
                            <b>{data.gizlilik_sözlesmesi_baslik} </b>&nbsp;
                          </a>
                          ve
                          <a
                            target="_blank"
                            href={`/${language}/mesafeli-satis-sozlesmesi`}
                          >
                            &nbsp;<b>{data.satis_sozlesmesini} </b>&nbsp;
                          </a>
                          {data.gizlilik_sözlesmesi_baslik_2}
                        </label>
                        <br />
                      </div>
                    </div>
                  </Accordion>
                </div>
                <div className="col-xl-6 col-md-12 col-12 mt-lg-0  mt-5 mx-lg-3 mx-md-3">
                  <h5>{data.siparis_tutari} </h5>
                  {cartShopping.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between orders-all col-12"
                    >
                      <div className="col-lg-2 col-md-2 col-3 p-2">
                        {item ? (
                          <img
                            crossOrigin="anonymous"
                            className="check-out-img"
                            src={
                              item.product_image_url
                                ? REACT_APP_BASE_URL + item.product_image_url
                                : noImage
                            }
                            alt={item.product_name}
                          />
                        ) : (
                          <img alt="perfume atelier" src={noImage}></img>
                        )}
                      </div>
                      <div className="d-lg-flex d-md-flex col-lg-8 col-md-8 col-6 mb-lg-0 mb-md-0 mb-3">
                        <div className="col-lg-7 col-md-7 p-2 d-flex flex-column justify-content-center">
                          <h6 className="p-0 m-0 w-100">{item.product_name}</h6>
                          <span>{item.product_image_attributes} </span>
                        </div>

                        <div className="col-lg-7 col-md-7 p-2  d-flex align-items-center justify-content-between ">
                          <div className="counter col-lg-5 col-md-5 col-12 d-flex p-1 ">
                            <div
                              className="col-4 d-flex justify-content-center counter-inner"
                              onClick={() => decreaseCounterHandler(index)}
                            >
                              -
                            </div>
                            <div className="col-4 d-flex justify-content-center ">
                              {item.count
                                ? item.count.toString().padStart(2, "0")
                                : ""}
                            </div>
                            <div
                              className="col-4 d-flex  justify-content-center counter-inner"
                              onClick={() => increaseCounterHandler(index)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-2 d-flex align-items-center justify-content-end mb-3">
                        <h6 className="p-0 m-0">
                          {(
                            item.count * parseFloat(item.product_price)
                          ).toFixed(2)}
                          ₺
                        </h6>
                      </div>
                    </div>
                  ))}

                  <div className="orders-all">
                    <div className="d-flex justify-content-between mt-3 ">
                      <p>{data.ara_toplam} </p>
                      <h6>{calculateTotalAmount().toFixed(2)}₺</h6>
                    </div>
                  </div>
                  <div className="orders-all">
                    <div className="mt-3 mb-3">
                      {showDiscountInput ? (
                        <div className="input-container w-100 cursor-pointer">
                          <input
                            className="w-100"
                            type="text"
                            placeholder="İndirim Kodu"
                            value={discountCode}
                            onChange={(e) => setDiscountCode(e.target.value)}
                          />
                          <button
                            className="apply-button button-type-one"
                            id="submit_btn"
                            onClick={(e) => handleApplyDiscount(e)}
                          >
                            {data.uygula}
                          </button>
                        </div>
                      ) : (
                        <p
                          className="m-0 p-0 cursor-pointer"
                          onClick={() => setShowDiscountInput(true)}
                        >
                          {data.indirim_kodu_ekle}
                        </p>
                      )}
                    </div>
                  </div>
                  {isDiscountValid && (
                    <div className="mt-3 mb-3">
                      <button
                        className="button-type-one w-100"
                        onClick={() => {
                          setDiscount(0);
                          setIsDiscountValid(false);
                          setShowDiscountInput(false);
                          setcouponid("");
                        }}
                      >
                        {data.kodu_kaldir}
                      </button>
                    </div>
                  )}

                  <div className="orders-all">
                    {isDiscountValid && (
                      <div className=" d-flex justify-content-between mt-3 mb-3">
                        <h6>{data.indirim_tutari} </h6>
                        <h6>-&nbsp;{indirimTutari.toFixed(2)}₺</h6>
                      </div>
                    )}
                    <div className=" d-flex justify-content-between mt-3 mb-3">
                      <h5>
                        <b>{data.toplam} </b>
                      </h5>
                      <h5>
                        <b>
                          {(
                            calculateTotalAmount() - parseFloat(indirimTutari)
                          ).toFixed(2)}
                          ₺
                        </b>
                      </h5>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6>{data.kargo} </h6>
                      <h6>
                        {cargoPrice == 0
                          ? "Ücretsiz"
                          : cargoPrice.toFixed(2) + "₺"}{" "}
                      </h6>
                    </div>
                    <div className=" d-flex justify-content-between mt-3 mb-3">
                      <h5>
                        <b>{data.ödenecek_tutar} </b>
                      </h5>
                      <h5>
                        <b>{finalTotal.toFixed(2)}₺</b>
                      </h5>
                    </div>
                  </div>
                  <button
                    className="button-type-two w-100 mt-3 mb-3 p-2 d-flex align-items-center gap-2 justify-content-center"
                    id="orders_btn"
                    onClick={(e) => ordersSubmit(e)}
                    disabled={isSubmitting}
                  >
                    <img
                      className="svg-icon"
                      src={sepetIcon}
                      alt="perfume atelier"
                    ></img>
                    {data.siparisi_tamamla}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
  };
};

export default connect(mapStateToProps)(CheckoutComponent);
